// @flow
import React from 'react'
import { Link } from 'gatsby'
import Layout, { SEO } from '../components/Layout'

export default function Home() {
  return (
    <Layout>
      <SEO title="Uwe-Christian Metz, Architekt Potsdam" />

      <h1>Seite nicht gefunden</h1>

      <p>
        <Link to="/">Zurück zur Startseite</Link>
      </p>
    </Layout>
  )
}
